<template>
  <div>
    <ManageForm6 />
  </div>
</template>
<script>
import ManageForm6 from "@/components/ManageForm6/ManageForm6";
export default {
  components: {
    ManageForm6,
  },
  created() { this.year = JSON.parse(
        Decode.decode(localStorage.getItem("yearSelected"))
      );
  },
};
</script>